import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Hero from '../components/hero';
import SEO from '../components/seo';
// import AboutUs from '../components/about-us';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />

    {/* <AboutUs /> */}
  </Layout>
);

export default IndexPage;
